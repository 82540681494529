<template>
  <b-card>
    <div class="row mb-2 align-items-end">
      <div class="col-8 d-flex items-center">
        <h4 class="font-weight-bold">{{ title }}</h4>
      </div>
    </div>

    <b-overlay :show="isLoading" rounded opacity="0.6" spinner-small spinner-variant="primary">
      <section class="row mb-8">
        <div class="col-12 col-xl-8">
          <b-form-group
            label-cols="12"
            label-cols-lg="2"
            label-size="sm"
            :label="variable.name || variable.key"
            label-for="type-name"
            v-for="(variable, i) in form.variables"
            :key="i"
          >
            <b-form-input
              id="type-name"
              class="mb-2 mr-sm-2 mb-sm-0"
              v-model="variable.value"
              :disabled="true"
            ></b-form-input>
          </b-form-group>
        </div>
      </section>
    </b-overlay>

    <div class="d-flex justify-content-center">
      <b-overlay
        :show="isSubmmiting"
        rounded
        opacity="0.6"
        spinner-small
        spinner-variant="primary"
        class="d-inline-block"
      >
        <b-button variant="success"  class="mr-2" @click="handleSubmit" :disabled="lineDisabled">
           啟用 LINE 設定 
        </b-button>
        <b-button variant="success" @click="handleSubmitWebhook" :disabled="webhookDisabled">
           設定 webhook  
        </b-button>
      </b-overlay>
    </div>
  </b-card>
</template>

<script>
import { reactive } from "@vue/composition-api";
import { mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import organizationVariableApi from "@/apis/organization-variable";
import organizationApi from "@/apis/organization";
import orgNameMixin from "@/mixins/organizationName"
import merchantApi from "@/apis/merchant";
import _ from "lodash";
// import * as consts from "@/consts";

export default {
  mixins: [orgNameMixin],
  setup() {
    const state = reactive({
      form: {
      },
    });
    const rules = {
      form: {
      },
    };

    const v$ = useVuelidate(rules, state);

    return { state, v$ };
  },
  data() {
    return {
      isLoading: false,
      isSubmmiting: false,
      form: {
        variables: [
          {
            "id": null,
            "value": null,
            "key": "LINE_CHANNEL_TOKEN",
            "name": 'LINE TOKEN',
          },
          {
            "id": null,
            "value": null,
            "name": 'LINE OA ID (不含 @)',
            "key": "LINE_OA_ID",
          },
          {
            "id": null,
            "value": null,
            "key": "LINE_OA_URL",
            "name":'LINE OA 網址',
          },
          {
            "id": null,
            "value": null,
            "key": "LINE_PORTAL_LIFF_ID",
            "name": "LIFF ID"
          }
        ]
      },
      hasOa: false,
      lineMerchant: null,
      keys: ['LINE_PORTAL_LIFF_ID', 'LINE_CHANNEL_TOKEN', 'LINE_OA_ID', 'LINE_OA_URL'],
      keysForEnable: ['LINE_LOGIN_CHANNEL_ID', 'LINE_LOGIN_CHANNEL_SECRET', 'LINE_MESSAGING_CHANNEL_ID', 'LINE_MESSAGING_CHANNEL_SECRET'],
      lineDisabled: true,
      webhookDisabled: true,
      keyPairsForValidation:{},
    };
  },
  validations() {
    return {
      form: {
        name: { required },
        type: { required },
        type_name: { required },
      },
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    breadcrumbs() {
      return [
        { to: { name: 'OrganizationHome' }, name: '系統參數設定' },
      ]
    },
    orgId() {
      return this.$store.state.general.organization.id;
    },
    title() {
      return '功能啟用'
    }
  },
  mounted() {
    if (!this.hasPermission()) {
      this.$swal
        .fire({
          type: "error",
          text: "你沒有權限訪問此頁面",
          confirmButtonColor: "#d33"
        })
        .then(() => {
        });
    }
    this.fetchLineSettingVariables();
  },
  methods: {
    hasPermission() {
      return this.$permissions.has(this.$permissions.consts.LINE_SETTING_MODIFY)
    },
    async fetchLineSettingVariables() {
      try {
        this.isLoading = true;
        const { data: r } = await organizationApi.getLineMerchant(this.orgId)
        this.lineMerchant = r.data
        let keys = this.keys.concat(this.keysForEnable)
        const { data } = await organizationVariableApi.list({
          org_code: this.orgId, keys: keys, merchant_type: 'line'
        })
        let mapping =  _.keyBy(data.data, 'key')
        this.form.variables = this.form.variables.map(variable => {
          if (variable.key in mapping) {
            variable.value = mapping[variable.key].value
            variable.id = mapping[variable.key].id
          }
          return variable
        })
        this.lineDisabled = data.data.some(key => this.keysForEnable.includes(key.key) && !key.value )
        this.webhookDisabled = data.data.some(key => this.keysForEnable.concat(['LINE_CHANNEL_TOKEN']).includes(key.key) && !key.value )
        data.data.map(key => this.keyPairsForValidation[key.key] = key.value)
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false
    },
    async updateChannelToken() {
      try {
        const { data } = await merchantApi.updateChannelToken(this.lineMerchant?.id);
        if (data.status && data.status === "success") {
          return true
        } else {
          return false
        }
      } catch (error) {
        return false
      }
    },
    async updateOa() {
      try {
        const { data } = await merchantApi.updateOaOnly(this.lineMerchant?.id);
        if (data.status === "success") {
          return true
        } else {
          return false
        }
      } catch (error) {
        return false
      }
    },
    async updateOrgVariables() {
      try {
        const { data } = await merchantApi.updateOrgVariables(this.lineMerchant?.id);
        if (data.status === "success") {
          return true
        }
        return false
      } catch (error) {
        return false
      }
    },
    async updateOrCreateLineLiff() {
      try {
        const { data } = await merchantApi.updateOrCreateLineLiff(this.lineMerchant?.id);
        if (data.code == 201) {
          return true
        } else if (data.status === "success") {
          return true
        } else {
          return false
        }
      } catch (error) {
        return false
      }
    },
    async updateLineWebhook() {
      try {
        const { data } = await merchantApi.updateLineWebhook(this.lineMerchant?.id);
        if (data.status === "success") {
          return true
        } else {
          return false
        }
      } catch (error) {
        return false
      }
    },
    async updateFriends() {
      try {
        const { data } = await merchantApi.updateFriends(this.lineMerchent.id);
        if (data.status === "success") {
          return true
        } else {
          return false
        }
      } catch (error) {
        return false
      }
    },
    async handleSubmitWebhook() {
      try {
        this.isSubmmiting = true;
        const { value: channelID } = await this.$swal.fire({
          title: "請輸入 LINE Messaging Channel ID",
          input: "text",
          inputLabel: "LINE Messaging Channel ID",
          inputPlaceholder: "LINE Messaging Channel ID"
        });

        if (channelID !== this.keyPairsForValidation.LINE_MESSAGING_CHANNEL_ID) {
          this.$swal.fire({
            type: "error",
            html: "LINE Messaging Channel ID 不符，無法設定"
          });
          return
        }

        let resultText = succuess => succuess ? "<span class='text-primary'> 完成 </span>" : "<span class='text-danger'> 失敗 </span>"
        let updateLineWebhookSuccessfully = resultText(await this.updateLineWebhook())
        let html = `
            設定組織 Webhook: ${updateLineWebhookSuccessfully} <br>
        `
        this.$swal.fire({
          type: "success",
          html,
        }).then(() => {
          this.fetchLineSettingVariables()
        });
      } catch (error) {
        const { status, data } = error.response;
        if (status && status === 422 && data.message) {
          const html = Object.values(data.message)
            .map((m) => m[0])
            .join("<br/>");
          this.$swal.fire({
            type: "error",
            html,
          });
        }
      } finally {
        this.isSubmmiting = false;
      }
    },
    async handleSubmit() {
      try {
        this.isSubmmiting = true;

        let resultText = succuess => succuess ? "<span class='text-primary'> 完成 </span>" : "<span class='text-danger'> 失敗 </span>"
        let updateChannelTokenSuccessfully = resultText(await this.updateChannelToken())
        let updateOaSuccessfully = resultText(resultText(await this.updateOa()))
        let updateOrgVariablesSuccessfully = resultText(await this.updateOrgVariables())
        let updateOrCreateLineLiffSuccessfully = resultText(await this.updateOrCreateLineLiff())
        let updateFriendsSuccessfully = resultText(await this.updateFriends())
        let html = `
            設定 LINE Messaging API Token: ${updateChannelTokenSuccessfully} <br>
            更新 OA: ${updateOaSuccessfully} <br>
            重設組織 LINE 參數: ${updateOrgVariablesSuccessfully} <br>
            建立組織 LIFF: ${updateOrCreateLineLiffSuccessfully} <br>
            更新好友: ${updateFriendsSuccessfully} <br>
        `
        this.$swal.fire({
          type: "success",
          html,
        }).then(() => {
          this.fetchLineSettingVariables()
        });
      } catch (error) {
        const { status, data } = error.response;
        if (status && status === 422 && data.message) {
          const html = Object.values(data.message)
            .map((m) => m[0])
            .join("<br/>");
          this.$swal.fire({
            type: "error",
            html,
          });
        }
      } finally {
        this.isSubmmiting = false;
      }
    },
    showSuccessPopup() {
      this.$swal
        .fire({
          type: "success",
          text:  "修改成功",
        })
        .then(() => {
          this.fetchLineSettingVariables()
        });
    },
  },
};
</script>
